// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPoliceContainer {
  max-width: 1920px;
  width: 100%;
  margin: 255px auto;
  padding: 0 20px 50px;
}

.PrivacyPoliceContainer h1 {
  text-align: center;
  padding: 10px 0;
  width: 100%;
  font-size: calc(21px + 19 * ((100vw - 320px) / (1920 - 320)));
}

.PrivacyPoliceContainer h3 {
  padding: 20px 0;
  width: 100%;
}

.PrivacyPoliceContainer p {
  font-size: 20px;
}

@media (max-width: 899px) {
  .PrivacyPoliceContainer {
    margin: 175px auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/footer/privacyPolicy/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,6DAA6D;AAC/D;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".PrivacyPoliceContainer {\n  max-width: 1920px;\n  width: 100%;\n  margin: 255px auto;\n  padding: 0 20px 50px;\n}\n\n.PrivacyPoliceContainer h1 {\n  text-align: center;\n  padding: 10px 0;\n  width: 100%;\n  font-size: calc(21px + 19 * ((100vw - 320px) / (1920 - 320)));\n}\n\n.PrivacyPoliceContainer h3 {\n  padding: 20px 0;\n  width: 100%;\n}\n\n.PrivacyPoliceContainer p {\n  font-size: 20px;\n}\n\n@media (max-width: 899px) {\n  .PrivacyPoliceContainer {\n    margin: 175px auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
