// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Banner_banner__XZq0T {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .Banner_banner__XZq0T {
    height: 143px;
  }
}

.Banner_banner__XZq0T a {
  font-size: calc(40px + 45 * ((100vw - 320px) / (1920 - 320)));
  color: #fff;
  font-family: "Open Sans";
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}
.Banner_banner__XZq0T a:hover {
  transition: all 0.5s;
  -webkit-transform: scale(1.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/banner/Banner.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;AACA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,6DAA6D;EAC7D,WAAW;EACX,wBAAwB;EACxB,gBAAgB;EAChB,kBAAkB;EAClB,qBAAqB;AACvB;AACA;EACE,oBAAoB;EACpB,6BAA6B;AAC/B","sourcesContent":[".banner {\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 250px;\n  margin-top: 10px;\n}\n@media (max-width: 767px) {\n  .banner {\n    height: 143px;\n  }\n}\n\n.banner a {\n  font-size: calc(40px + 45 * ((100vw - 320px) / (1920 - 320)));\n  color: #fff;\n  font-family: \"Open Sans\";\n  font-weight: 700;\n  text-align: center;\n  text-decoration: none;\n}\n.banner a:hover {\n  transition: all 0.5s;\n  -webkit-transform: scale(1.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": `Banner_banner__XZq0T`
};
export default ___CSS_LOADER_EXPORT___;
