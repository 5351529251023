// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_bannerContainer__8e12N {
  margin: 165px auto 0;
}

@media (max-width: 899px) {
  .Main_bannerContainer__8e12N {
    margin: 150px 0 0 0;
  }
}

.Main_banner__0m\\+v4 {
  display: inline-flex;
  /* padding: 82px 461px; */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  margin-top: 10px;
}

.Main_banner__0m\\+v4 a {
  color: #fff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  font-size: 4vw;
}

.Main_banner__0m\\+v4 a:hover {
  -webkit-transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/pages/main/Main.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,oBAAoB;EACpB,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".bannerContainer {\n  margin: 165px auto 0;\n}\n\n@media (max-width: 899px) {\n  .bannerContainer {\n    margin: 150px 0 0 0;\n  }\n}\n\n.banner {\n  display: inline-flex;\n  /* padding: 82px 461px; */\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 300px;\n  margin-top: 10px;\n}\n\n.banner a {\n  color: #fff;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  text-decoration: none;\n  font-size: 4vw;\n}\n\n.banner a:hover {\n  -webkit-transform: scale(1.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `Main_bannerContainer__8e12N`,
	"banner": `Main_banner__0m+v4`
};
export default ___CSS_LOADER_EXPORT___;
