import axios from "axios";

let allGoodsCache = null; // Глобальная переменная для хранения кэша

export const categoriesApi = {
  getAll: async () => {
    // Проверка кэша
    if (allGoodsCache) {
      return allGoodsCache;
    }

    const res = await axios.get("https://dali-khinkali.ru/api/goods/");
    allGoodsCache = res.data.results; // Заполнение кэша
    return allGoodsCache;
  },
  getBuisnessLunch: async () => {
    const all = await categoriesApi.getAll();
    return all.filter((el) => el.type === "Бизнес ланч");
  },
  getSalads: async () => {
    const all = await categoriesApi.getAll();
    return all.filter((el) => el.type === "Салаты");
  },
  getSnacks: async () => {
    const all = await categoriesApi.getAll();
    return all.filter((el) => el.type === "Закуски");
  },
  getSoups: async () => {
    const all = await categoriesApi.getAll();
    return all.filter((el) => el.type === "Супы");
  },
  getGarnish: async () => {
    const all = await categoriesApi.getAll();
    return all.filter((el) => el.type === "Гарниры");
  },
  getBekery: async () => {
    const all = await categoriesApi.getAll();
    return all.filter((el) => el.type === "Выпечка");
  },
  getHotDishes: async () => {
    const all = await categoriesApi.getAll();
    return all.filter((el) => el.type === "Горячие блюда");
  },
  getDishesOnTheGrill: async () => {
    const all = await categoriesApi.getAll();
    return all.filter((el) => el.type === "Блюда на мангале");
  },
  getSauces: async () => {
    const all = await categoriesApi.getAll();
    return all.filter((el) => el.type === "Соусы");
  },
  getBread: async () => {
    const all = await categoriesApi.getAll();
    return all.filter((el) => el.type === "Хлеб");
  },
  getDessert: async () => {
    const all = await categoriesApi.getAll();
    return all.filter((el) => el.type === "Десерты");
  },
  getDrinks: async () => {
    const all = await categoriesApi.getAll();
    return all.filter((el) => el.type === "Напитки");
  },
  getSlider: async () => {
    const res = await axios.get(
      "https://dali-khinkali.ru/api/goods/?promotion=Новинки"
    );
    return res.data.results;
  },
};
