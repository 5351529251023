// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ! ===== Salats ===== */

.Dessert_content__-oIRw {
  text-align: center;
  margin-bottom: 70px;
  width: 100%;
  height: auto;
}

.Dessert_containerCardSalats__5YsLH {
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  border-color: rgb(234, 223, 223);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Dessert_cardSalat__-O33g,
.Dessert_weight__VHmsw {
  border-radius: 210.5px 210.5px 20px 20px;
  width: 386px;
}



.Dessert_BoxWeightSubTitle__gWK30 {
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  transition: all 0.5s;
}




.Dessert_cardSalat__-O33g:hover .Dessert_BoxWeightSubTitle__gWK30 {
  opacity: 1;
  max-height: 300px;
  visibility: visible;
}

@media (max-width: 900px) {
  .Dessert_containerCardSalats__5YsLH {
    justify-content: center;
  }
}

.Dessert_cardSalat__-O33g {
  background: #eaeaea;
  margin: 40px 20px 150px;
  transition: all 0.5s;
  cursor: pointer;
}

@media (max-width: 921px) {
  .Dessert_cardSalat__-O33g {
    margin: 100px 10px 150px;
  }
}

@media (max-width: 406px) {
  .Dessert_cardSalat__-O33g {
    width: 355px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/menu/dessert/Dessert.module.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;EACE,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,WAAW;EACX,iBAAiB;EACjB,gCAAgC;EAChC,aAAa;EACb,eAAe;EACf,6BAA6B;AAC/B;;AAEA;;EAEE,wCAAwC;EACxC,YAAY;AACd;;;;AAIA;EACE,UAAU;EACV,aAAa;EACb,kBAAkB;EAClB,oBAAoB;AACtB;;;;;AAKA;EACE,UAAU;EACV,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":["/* ! ===== Salats ===== */\n\n.content {\n  text-align: center;\n  margin-bottom: 70px;\n  width: 100%;\n  height: auto;\n}\n\n.containerCardSalats {\n  margin: 0 auto;\n  width: 100%;\n  max-width: 1920px;\n  border-color: rgb(234, 223, 223);\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n}\n\n.cardSalat,\n.weight {\n  border-radius: 210.5px 210.5px 20px 20px;\n  width: 386px;\n}\n\n\n\n.BoxWeightSubTitle {\n  opacity: 0;\n  max-height: 0;\n  visibility: hidden;\n  transition: all 0.5s;\n}\n\n\n\n\n.cardSalat:hover .BoxWeightSubTitle {\n  opacity: 1;\n  max-height: 300px;\n  visibility: visible;\n}\n\n@media (max-width: 900px) {\n  .containerCardSalats {\n    justify-content: center;\n  }\n}\n\n.cardSalat {\n  background: #eaeaea;\n  margin: 40px 20px 150px;\n  transition: all 0.5s;\n  cursor: pointer;\n}\n\n@media (max-width: 921px) {\n  .cardSalat {\n    margin: 100px 10px 150px;\n  }\n}\n\n@media (max-width: 406px) {\n  .cardSalat {\n    width: 355px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Dessert_content__-oIRw`,
	"containerCardSalats": `Dessert_containerCardSalats__5YsLH`,
	"cardSalat": `Dessert_cardSalat__-O33g`,
	"weight": `Dessert_weight__VHmsw`,
	"BoxWeightSubTitle": `Dessert_BoxWeightSubTitle__gWK30`
};
export default ___CSS_LOADER_EXPORT___;
