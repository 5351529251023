// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderAuth_IMaskInputReg__w8GlH {
  background: #fff;
  border-radius: 50px;
  padding: 22px 14px;
  margin: 15px 0 0 0;
  width: 100%;
}
@media (max-width: 1199px) {
  .HeaderAuth_IMaskInputReg__w8GlH {
    padding: 10.5px 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/header/headerAuth/HeaderAuth.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;AACA;EACE;IACE,oBAAoB;EACtB;AACF","sourcesContent":[".IMaskInputReg {\n  background: #fff;\n  border-radius: 50px;\n  padding: 22px 14px;\n  margin: 15px 0 0 0;\n  width: 100%;\n}\n@media (max-width: 1199px) {\n  .IMaskInputReg {\n    padding: 10.5px 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IMaskInputReg": `HeaderAuth_IMaskInputReg__w8GlH`
};
export default ___CSS_LOADER_EXPORT___;
