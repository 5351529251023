// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vozvrat_FormVozvrat__IxknV {
  display: flex;
  justify-content: space-around;
}

.Vozvrat_DelTitle__yt3oc {
  margin: 260px 0 0 0;
  width: 100%;
  text-align: center;
  color: black;
  display: block;
}

@media (max-width: 750px) {
  .Vozvrat_FormVozvrat__IxknV {
    flex-wrap: wrap;
  }
}

.Vozvrat_FormVozvratformLeft__V--cB {
  min-width: 462px;
  width: 100%;
  background: rgba(255, 166, 0, 0.5215686275);
  margin: 0 30px 30px 0;
  padding: 20px;
  border-radius: 10px;
}

.Vozvrat_FormVozvratformLeft__V--cB h3 {
  padding: 0 0 20px 0;
}

.Vozvrat_FormVozvratInput__Kzpmj {
  border-radius: 10px;
  color: black;
  margin: 0 0 15px 0;
}

.Vozvrat_FormVozvratInput__Kzpmj input {
  color: black;
  border-radius: 5px;
  width: 100%;
  padding: 10px 5px;
}

.Vozvrat_FormVozvratInput__Kzpmj input::placeholder {
  font-size: 10px;
  font-size: calc(15px + 12 * ((100vw - 320px) / (1920 - 320)));
}

.Vozvrat_FormVozvratformRight__zzQ2x ul li {
  color: black;
}

.Vozvrat_buttonVozvrat__GwJQD {
  font-size: calc(12px + 10 * ((100vw - 320px) / (1920 - 320)));
  padding: 20px 50px;
  color: black;
  border-radius: 10px;
  font-weight: bold;
  background: #49b52e;
}

.Vozvrat_buttonVozvrat__GwJQD:hover {
  background: #3dbb1e;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/vozvrat/Vozvrat.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,2CAA2C;EAC3C,qBAAqB;EACrB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,6DAA6D;AAC/D;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,6DAA6D;EAC7D,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".FormVozvrat {\n  display: flex;\n  justify-content: space-around;\n}\n\n.DelTitle {\n  margin: 260px 0 0 0;\n  width: 100%;\n  text-align: center;\n  color: black;\n  display: block;\n}\n\n@media (max-width: 750px) {\n  .FormVozvrat {\n    flex-wrap: wrap;\n  }\n}\n\n.FormVozvratformLeft {\n  min-width: 462px;\n  width: 100%;\n  background: rgba(255, 166, 0, 0.5215686275);\n  margin: 0 30px 30px 0;\n  padding: 20px;\n  border-radius: 10px;\n}\n\n.FormVozvratformLeft h3 {\n  padding: 0 0 20px 0;\n}\n\n.FormVozvratInput {\n  border-radius: 10px;\n  color: black;\n  margin: 0 0 15px 0;\n}\n\n.FormVozvratInput input {\n  color: black;\n  border-radius: 5px;\n  width: 100%;\n  padding: 10px 5px;\n}\n\n.FormVozvratInput input::placeholder {\n  font-size: 10px;\n  font-size: calc(15px + 12 * ((100vw - 320px) / (1920 - 320)));\n}\n\n.FormVozvratformRight ul li {\n  color: black;\n}\n\n.buttonVozvrat {\n  font-size: calc(12px + 10 * ((100vw - 320px) / (1920 - 320)));\n  padding: 20px 50px;\n  color: black;\n  border-radius: 10px;\n  font-weight: bold;\n  background: #49b52e;\n}\n\n.buttonVozvrat:hover {\n  background: #3dbb1e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormVozvrat": `Vozvrat_FormVozvrat__IxknV`,
	"DelTitle": `Vozvrat_DelTitle__yt3oc`,
	"FormVozvratformLeft": `Vozvrat_FormVozvratformLeft__V--cB`,
	"FormVozvratInput": `Vozvrat_FormVozvratInput__Kzpmj`,
	"FormVozvratformRight": `Vozvrat_FormVozvratformRight__zzQ2x`,
	"buttonVozvrat": `Vozvrat_buttonVozvrat__GwJQD`
};
export default ___CSS_LOADER_EXPORT___;
