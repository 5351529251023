import React from "react";
import Taxi from "../taxi.js";

export const Ifrem = () => {
  return (
    <div>
      <p>
        Loremalskdjfa;lskjdfl;aksjdfl;aksjdf;lkajsfd;lkajsf;ljasd;fljasl;dfjl;sadjfl;jasvlkjcls;jkv;laksjdf;lkajsdf;lkjasd;flkjas;lfjds
      </p>
    </div>
  );
};
