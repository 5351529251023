// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  /*text-align: center;*/
  min-height: 100vh;
  /*background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('./assets/images/background.png'), lightgray 50% / cover no-repeat;*/
}


.uppercase{
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,+JAA+J;AACjK;;;AAGA;EACE,yBAAyB;AAC3B","sourcesContent":[".App {\n  /*text-align: center;*/\n  min-height: 100vh;\n  /*background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('./assets/images/background.png'), lightgray 50% / cover no-repeat;*/\n}\n\n\n.uppercase{\n  text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
