// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderModal_toggleNavBar__lzplm {
  background: #ffffff;
  border-right: 3px solid #f69049;
  border-bottom: 3px solid #f69049;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: block;
  padding: 25px 25px 25px;
  position: absolute;
  left: -20px;
  top: 64px;
  min-width: 270px;
  max-height: 89vh;
  overflow: scroll;
  z-index: 1000;
}

.HeaderModal_toggleNavBar__lzplm a {
  margin: 0 0 15px 0;
  color: #000;
  font-family: "Open Sans";
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  font-size: calc(15px + 5 * ((100vw - 320px) / (1920 - 320)));
  display: flex;
}

.HeaderModal_toggleNavBar__lzplm a:focus {
  color: #f69049;
}

.HeaderModal_CloseIcon__-S4XE {
  position: absolute;
  right: 28px;
}

.HeaderModal_NavItemBox__DwDya {
  margin: 30px 0 0 0;
  padding: 0 0 0 10px;
}

.HeaderModal_NavItemBox__DwDya button {
  background: none;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 30px;
  padding: 10px 10px;
  margin: 0 5px 5px;
}

.HeaderModal_NavItemBox__DwDya button:focus {
  background: #f69049;
  color: white;
}

.HeaderModal_backIconToggle__p1Fl5 {
  width: 30px;
  height: 30px;
  border: 1px solid black;
  border-radius: 50px;
  padding: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/header/headerModal/HeaderModal.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,+BAA+B;EAC/B,gCAAgC;EAChC,6BAA6B;EAC7B,gCAAgC;EAChC,cAAc;EACd,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,wBAAwB;EACxB,gBAAgB;EAChB,yBAAyB;EACzB,qBAAqB;EACrB,mBAAmB;EACnB,4DAA4D;EAC5D,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".toggleNavBar {\n  background: #ffffff;\n  border-right: 3px solid #f69049;\n  border-bottom: 3px solid #f69049;\n  border-top-right-radius: 20px;\n  border-bottom-right-radius: 20px;\n  display: block;\n  padding: 25px 25px 25px;\n  position: absolute;\n  left: -20px;\n  top: 64px;\n  min-width: 270px;\n  max-height: 89vh;\n  overflow: scroll;\n  z-index: 1000;\n}\n\n.toggleNavBar a {\n  margin: 0 0 15px 0;\n  color: #000;\n  font-family: \"Open Sans\";\n  font-weight: 600;\n  text-transform: uppercase;\n  text-decoration: none;\n  white-space: nowrap;\n  font-size: calc(15px + 5 * ((100vw - 320px) / (1920 - 320)));\n  display: flex;\n}\n\n.toggleNavBar a:focus {\n  color: #f69049;\n}\n\n.CloseIcon {\n  position: absolute;\n  right: 28px;\n}\n\n.NavItemBox {\n  margin: 30px 0 0 0;\n  padding: 0 0 0 10px;\n}\n\n.NavItemBox button {\n  background: none;\n  cursor: pointer;\n  border: 1px solid black;\n  border-radius: 30px;\n  padding: 10px 10px;\n  margin: 0 5px 5px;\n}\n\n.NavItemBox button:focus {\n  background: #f69049;\n  color: white;\n}\n\n.backIconToggle {\n  width: 30px;\n  height: 30px;\n  border: 1px solid black;\n  border-radius: 50px;\n  padding: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleNavBar": `HeaderModal_toggleNavBar__lzplm`,
	"CloseIcon": `HeaderModal_CloseIcon__-S4XE`,
	"NavItemBox": `HeaderModal_NavItemBox__DwDya`,
	"backIconToggle": `HeaderModal_backIconToggle__p1Fl5`
};
export default ___CSS_LOADER_EXPORT___;
