// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/img/background__REG__LOG.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Regsuccessfully_backgroundRegSuccess__2ZGDO {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.Regsuccessfully_RegSuccessBox__XRrUU {
  background: linear-gradient(0deg,
      rgba(36, 36, 36, 0.85) 0%,
      rgba(36, 36, 36, 0.85) 100%);
  border-radius: 60px;
  max-width: 700px;
  min-height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Regsuccessfully_RegSuccessBox__XRrUU img {
  width: 110px;
  height: 110px;
}

.Regsuccessfully_RegSuccessBox__XRrUU p {
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: calc(20px + 12 * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
  line-height: 137.5%;
  text-transform: uppercase;
  padding: 0 0 20px 0;
}

.Regsuccessfully_RegSuccessBox__XRrUU span {
  color: white;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/taxi/regsuccessfully/Regsuccessfully.module.css"],"names":[],"mappings":"AAEA;EACE,oEAA8E;EAC9E,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,cAAc;AAChB;;AAEA;EACE;;kCAEgC;EAChC,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,qCAAqC;EACrC,6DAA6D;EAC7D,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,qCAAqC;EACrC,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap\");\n\n.backgroundRegSuccess {\n  background: url(../../../assets/img/background__REG__LOG.png) center no-repeat;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 99999;\n}\n\n.RegSuccessBox {\n  background: linear-gradient(0deg,\n      rgba(36, 36, 36, 0.85) 0%,\n      rgba(36, 36, 36, 0.85) 100%);\n  border-radius: 60px;\n  max-width: 700px;\n  min-height: 500px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.RegSuccessBox img {\n  width: 110px;\n  height: 110px;\n}\n\n.RegSuccessBox p {\n  color: #fff;\n  text-align: center;\n  font-family: \"Montserrat\", sans-serif;\n  font-size: calc(20px + 12 * ((100vw - 320px) / (1920 - 320)));\n  font-weight: 700;\n  line-height: 137.5%;\n  text-transform: uppercase;\n  padding: 0 0 20px 0;\n}\n\n.RegSuccessBox span {\n  color: white;\n  text-align: center;\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 20px;\n  font-weight: 700;\n  text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundRegSuccess": `Regsuccessfully_backgroundRegSuccess__2ZGDO`,
	"RegSuccessBox": `Regsuccessfully_RegSuccessBox__XRrUU`
};
export default ___CSS_LOADER_EXPORT___;
