// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* !===== Menu-Pages ===== */
.Menu_bannerContainer__uSHsS {
  margin: 195px auto 0;
}

@media (max-width: 414px) {
  .Menu_bannerContainer__uSHsS {
    margin: 173px auto 0;
  }
}


.Menu_navLink__rbP4M {
  margin: 25px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {
  .Menu_navLink__rbP4M {
    display: none;
  }
}

.Menu_linkMenu__4wVMh {
  color: #000;
  font-family: "Lato";
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 8px;
  border: 3px solid #f69049;
  padding: 12px 38px;
  margin: 0px 20px 20px;
}

.Menu_linkMenu__4wVMh:focus {
  background: #f69049;
}

.Menu_SalatBlockRow__5BfNS {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Menu_BlockCard__kuvAP {
  border-radius: 210.5px 210.5px 20px 20px;
  width: 421px;
  background: #eaeaea;
  margin: 120px 87px;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

@media (max-width: 406px) {
  .Menu_bannerContainer__uSHsS {
    margin: 164px auto 0
  }

  .Menu_hrLine__aNBer {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/menu/Menu.module.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF;;;AAGA;EACE,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,wCAAwC;EACxC,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE;IACE;EACF;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":["/* !===== Menu-Pages ===== */\n.bannerContainer {\n  margin: 195px auto 0;\n}\n\n@media (max-width: 414px) {\n  .bannerContainer {\n    margin: 173px auto 0;\n  }\n}\n\n\n.navLink {\n  margin: 25px auto 0;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n}\n\n@media (max-width: 900px) {\n  .navLink {\n    display: none;\n  }\n}\n\n.linkMenu {\n  color: #000;\n  font-family: \"Lato\";\n  font-size: 40px;\n  font-weight: 500;\n  letter-spacing: 1px;\n  border-radius: 8px;\n  border: 3px solid #f69049;\n  padding: 12px 38px;\n  margin: 0px 20px 20px;\n}\n\n.linkMenu:focus {\n  background: #f69049;\n}\n\n.SalatBlockRow {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.BlockCard {\n  border-radius: 210.5px 210.5px 20px 20px;\n  width: 421px;\n  background: #eaeaea;\n  margin: 120px 87px;\n}\n\nbutton {\n  border: none;\n  background: none;\n  cursor: pointer;\n}\n\n@media (max-width: 406px) {\n  .bannerContainer {\n    margin: 164px auto 0\n  }\n\n  .hrLine {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `Menu_bannerContainer__uSHsS`,
	"navLink": `Menu_navLink__rbP4M`,
	"linkMenu": `Menu_linkMenu__4wVMh`,
	"SalatBlockRow": `Menu_SalatBlockRow__5BfNS`,
	"BlockCard": `Menu_BlockCard__kuvAP`,
	"hrLine": `Menu_hrLine__aNBer`
};
export default ___CSS_LOADER_EXPORT___;
